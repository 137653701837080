* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
  outline: none;
  border: none;
  scroll-behavior: smooth;
  font-family: 'Inter';
  font-size: 0.8vw;
}

body {
  background: #f1f1f1;
}

a {
  text-decoration: none;
}
a:visited {
  color: unset;
}

button {
  background: none;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-VariableFont_slnt\,wght.ttf');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Dela Gothic One';
  src: url('./assets/fonts/DelaGothicOne-Regular.ttf');
  font-style: normal;
  font-display: swap;
}